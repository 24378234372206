import { createSelector } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { IChangePasswordState } from './state';

export const changePassword = (state: AppState) => state.changePasswordState;

export const changePassword_Form = createSelector(
  changePassword,
  (state: IChangePasswordState) => state.form
);
