import { commomState_SystemConfig_Config } from './../../../store/common/common.selectors';
import { AppState } from 'src/app/store/app.states';
// Angular
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

/**
 * Sanitize HTML
 */
@Pipe({
  name: 'tenantconfig'
})
export class TenantConfigPipe implements PipeTransform {
  /**
   * Pipe Constructor
   *
   * @param _sanitizer: DomSanitezer
   */
  // tslint:disable-next-line
  constructor(public store: Store<AppState>, protected _sanitizer: DomSanitizer) {
  }

  config$ = this.store.pipe(select(commomState_SystemConfig_Config));

  async transform(value: string): Promise<string> {
    const messages = await this.getValue(this.config$)

    return messages[value];
  }

  hasValue(value: any) {
    return value !== null && value !== undefined;
  }

  getValue<T>(observable: Observable<T>): Promise<T> {
    return observable
      .pipe(
        filter(this.hasValue),
        first()
      )
      .toPromise();
  }

}
