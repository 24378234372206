import { Directive, ElementRef, Renderer2, Input, Host, Self, Optional } from '@angular/core';
import { AppButtonComponent } from '@ifaa-components/ui-components';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.states';
import { commomState_IsLoading } from 'src/app/store/common/common.selectors';

@Directive({
  selector: '[loading]',
})
export class AppButtonDirective {


  constructor(
    public store: Store<AppState>,
    public renderer: Renderer2,
    public hostElement: ElementRef,
    @Host() @Self() @Optional() public button : AppButtonComponent) {

      if(this.button)
      this.button.isLoading = commomState_IsLoading;
  }

  ngOnChanges() {
    var dd = '';
  }
  ngAfterViewInit(): void {

  }
}
