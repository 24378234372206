import { AppState } from 'src/app/store/app.states';
import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ComponentBase } from '../../../../views/component-base';
import { commomState_SystemConfig_Config } from 'src/app/store/common/common.selectors';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.master.html',
  styleUrls: ['./footer.master.scss']
})
export class FooterComponent extends ComponentBase {

  tenantConfig$ = this.store.pipe(select(commomState_SystemConfig_Config));

  constructor(public store: Store<AppState>,
    public dialog: MatDialog,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }



}
