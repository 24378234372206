<footer id="footer-layout-container" class="mt-4 " *ngIf="tenantConfig$ | async; let tenant">
  <div class="container-fluid p-4">
    <div class="d-flex flex-row main">
      <div class="left-side d-flex align-items-center">
        <div style="text-align: center;">
          <img src="{{'fundLogo' | tenantconfig | async }}" routerLink="/dashboard" class="logo">
          <a class="no-text-decoration mt-4" href="tel:{{tenant.phoneNumber}}">{{tenant.phoneNumber}}</a>
          <a class="no-text-decoration" href="mailto:{{tenant.email}}">{{tenant.email}}</a>
          <!-- <div class="social d-flex flex-row">
            <a>
              <img src="https://vervassets.blob.core.windows.net/web/mp-facebook.png">
            </a>
            <a>
              <img src="https://vervassets.blob.core.windows.net/web/mp-linkedin.png">
            </a>
            <a>
              <img src="https://vervassets.blob.core.windows.net/web/mp-twitter.png">
            </a>
            <a>
              <img src="https://vervassets.blob.core.windows.net/web/mp-instagram.png">
            </a>
            <a>
              <img src="https://vervassets.blob.core.windows.net/web/mp-youtube.png">
            </a>

          </div> -->
        </div>
      </div>
      <div>
        <p>CIRT (QLD) PTY LTD ABN  49 011 050 329 Trustee of Contracting Industry Redundancy Trust (CIRT) ABN 18 414 343 407</p>
        <div class="d-flex">
        <div class="d-flex align-item-center mr-4">
          <mat-icon class="mr-2">phone</mat-icon> {{tenant.phoneNumber}}
        </div>
        <div class="d-flex align-item-center">
          <mat-icon class="mr-2">email</mat-icon> enquiries@cirt.com.au
        </div>
      </div>
        <br />

        Postal Address: GPO Box 701, Brisbane QLD 4001<br />

        Street Address: Level 1, East Tower, 410 Ann Street, Brisbane QLD 4000
        <p class="mt-3">
          <a href="https://www.cirt.com.au/index.php/privacy-policy-2/">Privacy policy</a>
        </p>
        <div class="partners d-flex flex-row">
          <a>
            <img class="ml-0" src="https://sharedassetstorage.blob.core.windows.net/web/CIRT/etu.png">
          </a>
          <a>
            <img src="https://sharedassetstorage.blob.core.windows.net/web/CIRT/MEA_CMYK.png">
          </a>
          <a>
            <img src="https://sharedassetstorage.blob.core.windows.net/web/CIRT/Mates_Logo.png">
          </a>
        </div>
      </div>
    </div>

  </div>
</footer>
