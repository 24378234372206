import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { LogoutRequestAction } from './common/common.actions';

export function clearState(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action.type === LogoutRequestAction.type) {
      state = undefined;
    }
    return reducer(state, action);
  };
}
